// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}

import "@nathanbate/admin-dashboard-vue/dist/assets/index.css"
//import '../css/app.css'
import { createApp } from 'vue';
import TimesheetReports from '../vue/TimesheetReports';
import HomePage from "../vue/Pages/HomePage.vue";

import Dashboard from "@nathanbate/admin-dashboard-vue/src/components/Dashboard.vue"
import MenuItem from "@nathanbate/admin-dashboard-vue/src/components/MenuItem.vue"

import List from "@nathanbate/vue-list/"

import TimeClock from "@/vue/components/TimeClock.vue";


const app = createApp({
    delimiters: ["${", "}$"],
    components: {
        'timesheet-reports' : TimesheetReports,
        'dashboard' : Dashboard,
        'menu-item' : MenuItem,
        'list' : List,
        'time-clock' : TimeClock,
		'home-page' : HomePage,
    }
});
app.mount('#app');